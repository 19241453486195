import React, { useState } from 'react';
import './Maintenance.css';

const Maintenance = ({ onCancel, onUnlock }) => {
    const [password, setPassword] = useState('');

    const handlePasswordChange = (e) => {
        const value = e.target.value;
        setPassword(value);
        
        // Vérifie si le mot de passe est correct
        if (value === 'unbot2025') {
            onUnlock();
        }
    };

    return (
        <div className="maintenance-container">
            <h2 className="maintenance-title">🛠️ En maintenance</h2>
            <p className="maintenance-text">
                Cette fonctionnalité est temporairement indisponible pendant que nous effectuons des améliorations.
                Nous travaillons dur pour la remettre en service le plus rapidement possible.
            </p>
            <input
                type="text"
                className="maintenance-code"
                value={password}
                onChange={handlePasswordChange}
                placeholder="Code d'accès"
            />
            <button
                className="button-outline"
                onClick={onCancel}
            >
                Retour
            </button>
        </div>
    );
};

export default Maintenance; 