import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import './SubscriptionDetails.css';

const SubscriptionDetails = ({ subscription, onBack }) => {
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [editedDetails, setEditedDetails] = useState(null);
  const [newFan, setNewFan] = useState('');

  useEffect(() => {
    fetchSubscriptionDetails();
  }, []);

  const fetchSubscriptionDetails = async () => {
    const jwt = Cookies.get('user_token');

    const config = {
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    };

    try {
      const response = await axios.get(`https://unbot.io/api/v1.0/subscription/${encodeURIComponent(subscription._id)}`, config);
      setSubscriptionDetails(response.data);
      setEditedDetails(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching subscription details:", error);
      setIsLoading(false);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    const jwt = Cookies.get('user_token');

    const config = {
      headers: {
        Authorization: `Bearer ${jwt}`
      }
    };

    try {
      await axios.patch(`https://unbot.io/api/v1.0/subscription/${encodeURIComponent(subscription._id)}`, editedDetails, config);
      setSubscriptionDetails(editedDetails);
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating subscription:", error);
    }
  };

  const handleCancel = () => {
    setEditedDetails(subscriptionDetails);
    setIsEditing(false);
  };

  const handleChange = (field, value) => {
    setEditedDetails(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleAddFan = () => {
    const fanName = newFan.trim().toLowerCase();
    if (fanName && !editedDetails.fan_list.includes(fanName)) {
      handleChange('fan_list', [...editedDetails.fan_list, fanName]);
      setNewFan('');
    }
  };

  const handleRemoveFan = (fanToRemove) => {
    handleChange('fan_list', editedDetails.fan_list.filter(fan => fan !== fanToRemove));
  };

  const calculateDaysSinceSubscribed = (date) => {
    const subscriptionDate = new Date(date);
    const currentDate = new Date();
    const differenceInTime = currentDate - subscriptionDate;
    return Math.floor(differenceInTime / (1000 * 3600 * 24));
  }

  const calculateDaysUntilRenewal = (date) => {
    const subscriptionDate = new Date(date);
    const now = new Date();
    const nextRenewalDate = new Date();
    nextRenewalDate.setDate(subscriptionDate.getDate());
    if (nextRenewalDate <= now) {
      nextRenewalDate.setMonth(nextRenewalDate.getMonth() + 1);
    }

    const currentDate = new Date();

    if (currentDate.toDateString() === nextRenewalDate.toDateString()) {
      return 0;
    }

    const differenceInTime = nextRenewalDate - currentDate;
    return Math.ceil(differenceInTime / (1000 * 3600 * 24));
  }

  const getModeSelectionText = (mode) => {
    switch (mode) {
      case 'all':
        return 'Répondre à tous les messages';
      case 'whitelist':
        return 'Répondre uniquement aux fans dans la liste';
      case 'blacklist':
        return 'Répondre uniquement aux fans qui ne sont pas dans la liste';
      default:
        return mode;
    }
  }

  const getVenteModeText = (mode) => {
    switch (mode) {
      case 'notif':
        return 'Lorsque il y a une vente potentiel vous recevrez une notification';
      case 'auto':
        return 'Le robot fera automatiquement la vente';
      default:
        return mode;
    }
  }

  if (isLoading || !subscriptionDetails) {
    return <div className="subscription-details-container">Chargement...</div>;
  }

  return (
    <div className="subscription-details-container">
      <button className="back-button" onClick={onBack}>
        ← Retour
      </button>
      
      <h1 className="details-title">Détails de l'abonnement</h1>
      
      <div className="details-card">
        <div className="details-header">
          <h2 className="creator-name">{subscriptionDetails?.creator?.name ?? ""}</h2>
          {!isEditing ? (
            <button className="edit-button" onClick={handleEdit}>
              Modifier
            </button>
          ) : (
            <div className="edit-actions">
              <button className="cancel-button" onClick={handleCancel}>
                Annuler
              </button>
              <button className="save-button" onClick={handleSave}>
                Enregistrer
              </button>
            </div>
          )}
        </div>
        
        <div className="details-section">
          <h3>Informations générales</h3>
          <p>Date d'abonnement : {new Date(subscriptionDetails.date).toLocaleDateString()}</p>
          <p>Jours d'abonnement : {calculateDaysSinceSubscribed(subscriptionDetails.date)} jours</p>
          <p>Prochain renouvellement : dans {calculateDaysUntilRenewal(subscriptionDetails.date)} jours</p>
        </div>

        <div className="details-section">
          <h3>Configuration du bot</h3>
          {isEditing ? (
            <>
              <div className="edit-field">
                <label>Mode de sélection :</label>
                <select 
                  value={editedDetails.mode_selection}
                  onChange={(e) => handleChange('mode_selection', e.target.value)}
                >
                  <option value="all">Répondre à tous les messages</option>
                  <option value="whitelist">Répondre uniquement aux fans dans la liste</option>
                  <option value="blacklist">Répondre uniquement aux fans qui ne sont pas dans la liste</option>
                </select>
              </div>
              <div className="edit-field">
                <label>Mode de vente :</label>
                <select 
                  value={editedDetails.vente_mode}
                  onChange={(e) => handleChange('vente_mode', e.target.value)}
                >
                  <option value="notif">Lorsque il y a une vente potentiel vous recevrez une notification</option>
                  <option value="auto">Le robot fera automatiquement la vente</option>
                </select>
              </div>
              <div className="edit-field">
                <label>Statut du robot :</label>
                <select 
                  value={editedDetails.monitor_status ? 'true' : 'false'}
                  onChange={(e) => handleChange('monitor_status', e.target.value === 'true')}
                >
                  <option value="true">Actif</option>
                  <option value="false">Inactif</option>
                </select>
              </div>
            </>
          ) : (
            <>
              <p>Mode de sélection : {getModeSelectionText(subscriptionDetails.mode_selection)}</p>
              <p>Mode de vente : {getVenteModeText(subscriptionDetails.vente_mode)}</p>
              <p>Statut du robot : {subscriptionDetails.monitor_status ? 'Actif' : 'Inactif'}</p>
            </>
          )}
        </div>

        <div className="details-section">
          <h3>Liste des fans</h3>
          {isEditing ? (
            <div className="fan-list-edit">
              <div className="fan-list-input">
                <input
                  type="text"
                  value={newFan}
                  onChange={(e) => setNewFan(e.target.value)}
                  placeholder="Ajouter un fan"
                  onKeyPress={(e) => e.key === 'Enter' && handleAddFan()}
                />
                <button className="add-fan-button" onClick={handleAddFan}>
                  Ajouter
                </button>
              </div>
              <div className="fan-list">
                {editedDetails.fan_list.length > 0 ? (
                  editedDetails.fan_list.map((fan, index) => (
                    <div key={index} className="fan-item">
                      <span>{fan}</span>
                      <button 
                        className="remove-fan-button"
                        onClick={() => handleRemoveFan(fan)}
                      >
                        ×
                      </button>
                    </div>
                  ))
                ) : (
                  <p className="no-fans-message">Aucun fan dans la liste</p>
                )}
              </div>
            </div>
          ) : (
            <div className="fan-list">
              {subscriptionDetails.fan_list.length > 0 ? (
                subscriptionDetails.fan_list.map((fan, index) => (
                  <div key={index} className="fan-item">
                    <span>{fan}</span>
                  </div>
                ))
              ) : (
                <p className="no-fans-message">Aucun fan dans la liste</p>
              )}
            </div>
          )}
        </div>

        <div className="details-section">
          <h3>Personnalité du bot</h3>
          {isEditing ? (
            <div className="edit-field">
              <textarea
                className="note-model"
                value={editedDetails.note_model}
                onChange={(e) => handleChange('note_model', e.target.value)}
                rows={4}
              />
            </div>
          ) : (
            <p className="note-model">{subscriptionDetails.note_model}</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionDetails; 