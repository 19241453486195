import React, { useState } from 'react';
import axios from 'axios';
import './UncoveLogin.css';

const UncoveLogin = ({ onLoginSuccess, onCancel }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setError('');
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setError('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setError('');

        try {
            const response = await axios.post('https://unbot.io/api/v1.0/uncovelogin', {
                email,
                password
            });

            if (response.data.success) {
                onLoginSuccess(response.data.uncoveUsername, response.data.token);
            } else {
                setError('Identifiants incorrects');
            }
        } catch (error) {
            if (error.response?.data?.error) {
                setError(error.response.data.error);
            } else {
                setError('Une erreur est survenue lors de la vérification des identifiants');
            }
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="uncove-login-container">
            <h2 className="uncove-login-title">Connexion au compte Uncove</h2>
            
            <form onSubmit={handleSubmit}>
                <input
                    type="email"
                    className="text-input"
                    placeholder="Email Uncove"
                    value={email}
                    onChange={handleEmailChange}
                    required
                />

                <input
                    type="password"
                    className="text-input"
                    placeholder="Mot de passe Uncove"
                    value={password}
                    onChange={handlePasswordChange}
                    required
                />

                {error && <div className="error-message">{error}</div>}

                <div className="button-group">
                    <button
                        type="button"
                        className="button-outline"
                        onClick={onCancel}
                    >
                        Annuler
                    </button>
                    <button
                        type="submit"
                        className="new-bot-button"
                        disabled={isLoading}
                    >
                        {isLoading ? 'Vérification...' : 'Se connecter'}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default UncoveLogin; 